<template>
  <div>
    <h1>MSC Nyaralás</h1>
    <div class="img">
      <div v-for="image in images" :key="image.id">
        <img :src="image.name" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../util/api";

export default {
  components: {},
  data() {
    return {
      images: [],
      lastOne: 0,
      scrolledToBottom: false,
      yoff: 0,
      done: true
    };
  },
  beforeCreate() {
    let self = this;
    Api.getImages(0)
      .then(function (response) {
        self.images = response.data;
        self.images.forEach((e) => {
          self.lastOne = e.id;
        });
      })
      .catch((err) => console.log(err));
  },
  methods: {
    scroll() {
      window.onscroll = () => {
        this.yoff = window.pageYOffset;
        console.log(this.yoff);
        console.log(document.documentElement.scrollTop);
        console.log(document.body.scrollTop);
        console.log(window.innerHeight);
        console.log(document.documentElement.offsetHeight);
        console.log("");

        if (
          document.documentElement.offsetHeight -
            window.innerHeight -
            window.innerHeight <
            window.pageYOffset &&
          this.done
        ) {
          this.done = false;
          this.plus();
        }
      };
    },
    plus() {
      let self = this;
      Api.getImages(self.lastOne)
        .then(function (response) {
          response.data.forEach((e) => {
            self.images.push(e);
          });

          self.images.forEach((e) => {
            self.lastOne = e.id;
          });
          self.done = true;
          //console.log(self.images);
        })
        .catch((err) => console.log(err));
    }
  },
  mounted() {
    this.scroll();
  },
};
</script>

<style scoped>
h1 {
  margin: 20px 0;
  text-align: center;
}
.img {
  max-width: 600px;
  margin: 0 auto;
}
img {
  max-width: 100%;
  margin: 0 auto;
}
</style>