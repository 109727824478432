import axios from "axios";

export default class Api {
  static getImages(lastid) {
    const url = "http://www.img.bendek.hu/api/getimages.php";
    return axios.post(url, {
        lastid: lastid
    });
  }

  static getCurrency() {
    const url = "http://www.img.bendek.hu/api/fixer.php";
    return axios.post(url);
  }

  static getFolders() {
    const url = "http://www.img.bendek.hu/api/folder.php";
    return axios.post(url);
  }
}